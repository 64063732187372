import React from 'react'

import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import {
  Section,
  HeroImageContainer,
  ContentContainer,
  Title,
  Text,
  MobileImageContainer,
  StarContainer,
} from './styles'
import { CenteredMobile, BuyNowLink } from '../CalMaxHero/styles'
import { CAL_BUY_LINK } from '../../utils/constants'
import YotpoStars from '../YotpoStars'

const CalendarHero = () => {
  const { heroImage, heroMobileImage } = useStaticQuery(graphql`
    query CalHeroQuery {
      heroImage: file(relativePath: { eq: "calendar/cal-hero.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      heroMobileImage: file(relativePath: { eq: "calendar/cal-hero-mobile.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)

  return (
    <Section>
      <HeroImageContainer>
        <GatsbyImage
          image={heroImage.childImageSharp.gatsbyImageData}
          alt="Skylight Calendar displayed on countertop"
        />
      </HeroImageContainer>
      <MobileImageContainer>
        <GatsbyImage
          image={heroMobileImage.childImageSharp.gatsbyImageData}
          alt="Skylight Calendar displayed on countertop"
        />
      </MobileImageContainer>
      <ContentContainer>
        <StarContainer>
          <YotpoStars color="#000" textColor="#444444" productId="6863425175604" />
        </StarContainer>
        <Title>
          Drop the Stress. <br />
          Not the Ball.
        </Title>
        <Text>
          Get the whole family organized with chore charts, dinner planning, and customizable lists
          — all on one display the whole family can use.
        </Text>
        <CenteredMobile>
          <BuyNowLink to={CAL_BUY_LINK}>Buy Now</BuyNowLink>
        </CenteredMobile>
      </ContentContainer>
    </Section>
  )
}

export default CalendarHero
